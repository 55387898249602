import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import ErrorPage from './error/Error'
import Rules from './rules/Rules'
import Gallery from './gallery/Gallery'
import HowToPlay from './howtoplay/HowToPlay'
import Home from './home/Home'
import DeckBuilder from './DeckBuilder/DeckBuilder'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/gallery",
    element: <Gallery />
  },
  {
    path: "/howtoplay",
    element: <HowToPlay />
  },
  {
    path: "/rules",
    element: <Rules />
  },
  {
    path: "/deckbuilder",
    element: <DeckBuilder />
  },
])

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
