
import React from "react"
import Hotbar from "../elements/Hotbar"
import { GalleryCard } from "./GalleryCard"
import './gallery.css'
import axios from "axios"
import { useState, useEffect } from "react"
import GalleryCardSet from "./GalleryCardSet"
import { Card } from "./GalleryCardSet"

export default function Gallery() {

    const [cards, setCards] = useState<Card[]>([])

    useEffect(() => {

        axios.get("https://brooks42.github.io/Mooks/cards.json").then((res) => {
            setCards(res.data)
            console.log(`downloaded cards ${JSON.stringify(res)}`)
        })
    }, [])

    function getCardsOfType(type: string) {
        return cards.filter((card) => {
            return card.cardtype === type
        })
    }

    let bases = getCardsOfType('Base')
    let mooks = getCardsOfType('Mook')
    let machines = getCardsOfType('Machine')
    let factions = getCardsOfType('Faction')
    let schemes = getCardsOfType('Scheme')
    let blueprints = getCardsOfType('Blueprint')

    return (
        <div className="gallery">
            <div className="content-body">
                <div className="full-article">
                    <p>
                        Gallery
                    </p>
                    <Hotbar />
                    <p>
                        Search (coming soon) <input disabled={true} />
                    </p>
                    <p>We have a reddit here <a href="https://www.reddit.com/r/mookstcg/" target="_blank">mookstcg</a></p>
                    <p>
                        Interested in playtesting? A discord? Just want to learn more? Email me at info[at symbol]mookstcg.com!
                    </p>

                    {cards && factions.length > 0 && <div>
                        <h2 className="gallery-header">Factions</h2>
                        <GalleryCardSet cards={factions} />
                    </div>}

                    {cards && bases.length > 0 && <div>
                        <h2 className="gallery-header">Bases</h2>
                        <GalleryCardSet cards={bases} />
                    </div>}

                    {cards && mooks.length > 0 && <div>
                        <h2 className="gallery-header">Mooks</h2>
                        <GalleryCardSet cards={mooks} />
                    </div>}

                    {cards && schemes.length > 0 && <div>
                        <h2 className="gallery-header">Schemes</h2>
                        <GalleryCardSet cards={schemes} />
                    </div>}

                    {cards && machines.length > 0 && <div>
                        <h2 className="gallery-header">Machines</h2>
                        <GalleryCardSet cards={machines} />
                    </div>}

                    {cards && blueprints.length > 0 && <div>
                        <h2 className="gallery-header">Blueprints</h2>
                        <GalleryCardSet cards={blueprints} />
                    </div>}
                </div>
            </div>
        </div>
    )
}