
import Hotbar from '../elements/Hotbar'
import './../index.css'
import './Home.css'
import React from "react"

export default function Home() {
    return (
        <div className="home">

            <div className="content-body">
                <div className="full-article">
                    <p>
                        Home
                    </p>
                    <Hotbar />
                    <p>
                        The Mooks card game is coming soon!
                    </p>
                    <p>We have a reddit here <a href="https://www.reddit.com/r/mookstcg/" target="_blank">mookstcg</a></p>
                    <p>
                        Interested in playtesting? A discord? Just want to learn more? Email me at info[at symbol]mookstcg.com!
                    </p>
                    <div className='spacer'></div>
                    <h1>What is Mooks?</h1>
                    <h2>Background</h2>
                    <p>In the grim darkness of the year 2030ish, the world as we know it is coming to an end. The ice caps have melted, the magnetic poles have reversed,
                        and the world has been plunged into anarchy.</p>
                    <p>From this turmoil, four factions have formed in an effort to grab ultimate power and decide the fate of mankind. You choose one of these factions
                        and use hired Mooks, twisted schemes, and wild machines to win ultimate power.
                    </p>
                    <h2>Factions</h2>
                    <p>The world collapsing, along with the rise of advanced technologies and same-day global logistics, brought a chance for radical forces to compete on the world
                        stage. Shortly after the Climate Wars began, the first of the <b>Mad Scientists</b> rose to power in the city of Detroit.
                        The local government called in special forces and put an end to his Death Ray designs, but the technology and spirit of Mad Scientists
                        lives on.</p>
                    <p>As the world <b>Governments</b> began to lose their grip on the populace, they resorted to hiring mercenary groups to maintain control. Losing the
                        monopoly on force, Governments started to scrabble for new ways to maintain <i>control</i> over a humanity that was quickly fracturing into smaller and
                        smaller subgroups.</p>
                    <p>Flush with cash, the hired <b>Mercenaries</b> quickly consolidated their martial power, and now vie for control over the abandoned fortifications and
                        military bases that other groups have left behind. Their goal is nothing short of world domination with an iron fist.</p>
                    <p>As the other factions battle for the world, <b>Corporations</b> watch on and laugh. The major corporations of the world were the reason for the climate crises,
                        the civil unrest, and resource inequalities that made the fall of mankind possible. Now they look to exploit the remaining resources of a dying world,
                        pawing greedily for any amount of value they can produce for shareholders.</p>
                </div>
            </div>
        </div>
    )
}