
import React from "react"
import Hotbar from "../elements/Hotbar"

export default function DeckBuilder() {
    return (
        <div className="deck_builder">
            <div className="content-body">
                <div className="full-article">
                    <p>
                        Deck Builder
                    </p>
                    <Hotbar />
                    <p>We have a reddit here <a href="https://www.reddit.com/r/mookstcg/" target="_blank">mookstcg</a></p>
                    <p>
                        Interested in playtesting? A discord? Just want to learn more? Email me at info[at symbol]mookstcg.com!
                    </p>
                </div>
            </div>
        </div>
    )
}