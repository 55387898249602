import { GalleryCard } from "./GalleryCard"

export interface Card {
    cardname: string
    cardtype: string
    image?: string
}

interface GalleryCardSetProps {
    cards: Card[]
}

const imageHostUrl = 'https://brooks42.github.io/Mooks/images/jpegs/'

export default function GalleryCardSet(props: GalleryCardSetProps) {

    let galleryCardImages: JSX.Element[] = []

    props.cards.forEach((card) => {
        if (card.image) {
            galleryCardImages.push(<GalleryCard id={card.cardname} imageSrc={`${imageHostUrl}${card.image.replace('.png', '.jpeg')}`} />)
        }
    })

    return (
        <div id="gallery" className="gallery-container row small-up-3 medium-up-2 large-up-1">
            {galleryCardImages}
        </div>
    )
}