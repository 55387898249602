import { error } from "console"
import { useEffect, useState } from "react"


export interface GalleryCardProps {
    id: string
    imageSrc: string
}

/*img.addEventListener('error', function handleError() {
console.log(img.src);

img.style.display = 'none';
});*/
export function GalleryCard(props: GalleryCardProps) {

    let [loaded, setLoaded] = useState(false)
    let [errored, setErrored] = useState(false)

    return (
        <div className="gallery-block">
            {errored && <div className="gallery-card single-card-masked unloadable-card">
                Unable to load <br /> card image for <br />{props.id}
            </div>}
            {!errored &&
                <img id={props.id}
                    className="gallery-card single-card-masked"
                    style={loaded ? {} : { display: 'none' }}
                    src={props.imageSrc}
                    onLoad={() => {
                        console.log('loaded')
                        setLoaded(true)
                    }}
                    onError={() => {
                        console.log(`error loading ${props.id} filename ${props.imageSrc}`)
                        setErrored(true)
                    }} />}
        </div>
    )
}