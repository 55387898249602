
import './../index.css'
import React from "react"

export default function Hotbar() {
    return (
        <div className="hotbar">
            <a className="hotbar-link" href="/rules">Rules</a>
            <a className="hotbar-link" href="/howtoplay">How To Play</a>
            <a className="hotbar-link" href="/gallery">Gallery</a>
            <a className="hotbar-link" href="/">Advanced (coming soon)</a>
        </div>
    )
}