
import React from "react"
import Hotbar from "../elements/Hotbar"
import './rules.css'

export default function Rules() {
    return (
        <div className="rules">
            <div className="content-body">
                <div className="full-article">
                    <p>
                        Rules
                    </p>
                    <Hotbar />
                    <p>
                        The Mooks card game is coming soon!
                    </p>
                    <p>We have a reddit here <a href="https://www.reddit.com/r/mookstcg/" target="_blank">mookstcg</a></p>
                    <p>
                        Interested in playtesting? A discord? Just want to learn more? Email me at info[at symbol]mookstcg.com!
                    </p>

                    <h1 className="rules-title">Preparing for the game</h1>

                    <p>Each player needs a deck of 60 cards (called their Arsenal).
                        Arsenals can only contain at most three of any cards with the same name.
                        If a card has Unique, an Arsenal can only have one copy of that card.</p>

                    <h1 className="rules-title">Starting the game</h1>

                    <p>Each player shuffles their Arsenal, then takes the top six cards of their Arsenal and puts them facedown.
                        These are your Backup Plans.

                        Designate an area on the table for your Garbage Pile, usually close to your Arsenal.
                        This is where cards go when they're used up.

                        Decide who goes first.

                        Finally, each player draws six cards and play begins.</p>

                    <h1 className="rules-title">Turn Order</h1>

                    <p>Each player takes turns.</p>

                    <p><b>NOTE</b> you should remember your turn count for the first couple of turns, a card that cares about card count only cares about its controller's and it determines how many Mooks you can hire.</p>

                    <h2 className="rules-subtitle">What you can do during a turn</h2>

                    <p>At the start of your turn, you draw a card. Then abilities that trigger "at the start of your turn" trigger.

                        Each turn, you can:

                        <ul>
                            <li>play 1 Scheme</li>
                            <li>fabricate 1 machine that's been facedown since the start of the turn. You can also play any number of machines facedown</li>
                            <li>build any number of bases. If it's your only base, it becomes your Active Base automatically</li>
                            <li>hire a number of Mooks up to your current turn count, or until you control the most Mooks. If the count is 3 or higher, you can hire as many mooks as you want</li>
                            <li>once per turn, declare that you're assaulting (see "Fighting" below). You can't assault on your first turn</li>
                            <li>play any number of blueprints from your hand</li>
                            <li>activate your faction's Pressure ability if available</li>
                        </ul>
                    </p>

                    <h2>Assaulting</h2>

                    <p>
                        <ul>
                            <li>Attacker declares they're assaulting</li>
                            <li>Chooses any number of Mooks to assault with</li>
                            <li>Defending player chooses any number of Mooks to defend with</li>
                            <li>Attacking player pairs an unpaired attacking Mook and a defending Mook to fight, then defending player pairs an unpaired defending Mook and an attacking Mook. Repeat until all the mooks that can be paired are.</li>
                            <li>At the end of this process, if there are unpaired defenders, they're removed from combat.</li>
                            <li>Compare the Threat Level of paired Mooks. For each paired group, the attacker can blow up any number of defending mooks with total Threat Level less than the total Threat Level of the attackers, and the defender can blow up any number of attacking mooks with a Threat Level less than the total Threat Level of the defenders.</li>
                            <li>At the end, if there are remaining attackers and no remaining defenders, the assault is a success. If not, the assault fails.</li>
                        </ul>
                    </p>

                    <h1 className="rules-title">Winning the game</h1>
                    <p>
                        Players lose when they have to take a Backup Plan and have none to take, or if they go to draw a card and have none left in their Arsenal.
                    </p>
                    <p>
                        A player wins when either a card effect says they win, or all of the other players have lost.
                    </p>
                    <h1 className="rules-title">Backup Plans</h1>
                    <p>
                        Whenever you're assaulted successfully, you have to take a Backup Plan. To do that, put it into your hand from your Backup Plans pile.
                    </p>
                    <p>
                        If you have to take a Backup Plan because of a successful assault, and the pile is empty, you lose the game. If you have to take multiple Backup Plans at once, and have fewer than that in the pile, you take as many as you can but don't lose the game.
                    </p>
                    <p>
                        Some cards have an ability you can use if you take them as a Backup Plan. If you want to use that ability you must immediately do so as you take the card.
                    </p>
                    <h1 className="rules-title">Desperation</h1>

                    <p>You become Desperate the first time you take your last Backup Plan. Some cards are only usable while you're Desperate, or they get stronger while you're Desperate.

                    While you're Desperate, if a card is added to your Backup Plans, it's added faceup.</p>

                    <h1 className="rules-title">General Card Rules</h1>

                    <h2>Requirements</h2>

                    <p>Cards of all types can have requirements written on them that define when you're able to use them. 
                        A Machine may say "Requires 2 Mooks" — that means you have to control 2 Mooks to Fabricate the Machine. 
                        The same goes with hiring Mooks and playing Bases and Schemes. Once you play the card you no longer 
                        need to meet the requirements, so if you stop meeting the requirements the cards don't blow up.</p>

                    <h2>Faction-specific cards</h2>

                    <p>Some cards say "[Faction] Only". You can only put these cards in your Arsenal if your Faction card is that 
                    Faction. See the rules on Factions below for more information.</p>

                    <h2 className="rules-subtitle">Mooks</h2>

                    <p>Every Mook has a threat level. This is the number they use when fighting — it represents their 
                        ablity to deal damage or sustain damage when fighting. A higher threat level is better. If a 
                        Mook ever has a threat level of 0 they blow up (this is them retiring, going home, or turning
                        in their badge).</p>

                    <h2 className="rules-subtitle">Schemes</h2>

                    <p>Once per turn, during your Plan Step, you can play a Scheme from your hand. 
                        
                        You can also play a Scheme at any time — including during another player's turn — if an event 
                        occurs that meets the Scheme's timing restrictions. For example, if another player hires a Mook 
                        and you have a Scheme that says "You can play this Scheme when a Mook is hired", you can play that 
                        Scheme immediately. This doesn't get around the one Scheme card per turn restriction, though.</p>

                    <h3>Playing Schemes</h3>

                    <p>To play a Scheme, first verify that you can perform the full effect of the card. For example, if the Scheme says "Blow up target Mook", you must be able to target a Mook on the table. If it assigns targets, you must be able to assign all targets.

                    To play a Scheme, put the Scheme card on the table and declare any targets. If the card says "do X as a cost", you must do X. Then you do what the Scheme says. After you're done, put the Scheme card in your Garbage Pile.</p>

                    <h2 className="rules-subtitle">Bases</h2>

                    <p>If you have any Base cards on the table, you have an Active Base. When you play your first Base card, that becomes your Active Base. Make sure you clearly denote which Base is your active one, so no one gets confused if you have several bases. If your current Active Base is ever blown up, you immediately choose another of your Bases to become your current Active Base.

                    You only get the effect of your Active Base.</p>

                    <h3>Changing Active Base</h3>

                    <p>Once per turn you can change your current Active Base to another Base you control.</p>

                    <h2 className="rules-subtitle">Blueprints</h2>

                    <p>Blueprints are the ultimate expression of mad science, corporate might or government planning. Most blueprints represent alternate win conditions (or alternate ways to make your enemies lose).

                    You can play any number of Blueprints during your turn.</p>

                    <h2 className="rules-subtitle">Machines</h2>

                    <p>You can order any number of Machine cards facedown during your turn. Facedown Machines don't count as Machines for the purposes of targeting, paying costs, requirements, etc.

                    During your turn, you can fabricate one Machine you've controlled since the start of your turn. To fabricate it, turn it face up.</p>

                    <h2 className="rules-subtitle">Factions</h2>

                    <p>Faction cards define what your focus is. There are four Factions so far: Mad Scientists, and Mercs. There are several cards that say "[Faction] Only"; you can only play those cards if you have a Faction card of that Faction on the field.

                    If playing with Factions, you start the game with your Faction card on the field. Factions act as static cards and use their effects as described.

                    Each Faction also has a Pressure ability. You can only use this ability once per game, whenever you could play a Scheme.</p>

                    <h2 className="rules-subtitle">Tokens</h2>

                    <p>To hire a token, take the top card of your Arsenal and hire it facedown. It's a Mook with threat level 5.</p>

                    <h2 className="rules-subtitle">Keyword Glossary</h2>

                    <ul>
                        <li><b>Skilled Attacker</b>: While assaulting, this Mook can only be paired by its controller.</li>

                        <li><b>Skilled Defender</b>: While blocking, this Mook can only be paired by its controller.</li>

                        <li><b>Invincible</b>: This Mook cannot be blown up (it can still be targeted by things that would blow it up, it just doesn't get blown up by them).</li>

                        <li><b>Unique</b>: If a card has Unique, an Arsenal can only contain one copy of it.</li>

                        <li><b>Slippery</b>: If this Mook would be blown up in combat, remove it from combat instead.</li>
                    </ul>

                    <h2 className="rules-subtitle">FAQ</h2>

                    <ul>
                    <li>Whenever you search for a card with a stipulation (ie "a Machine" or "a Mook with threat level 4 or lower") you must reveal it.</li>

                    <li>Whenever you search your Arsenal for a card, you must shuffle afterwards.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}